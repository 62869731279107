import { Heading1, Heading2, Paragraph } from '../components/Typography';
import React from 'react';
import { faCreditCard, faThLarge } from '@fortawesome/free-solid-svg-icons';

import Button from '../components/Button';
import Grid from '../components/Grid/Grid';
import HeroSection from '../components/HeroSection';
import Link from '../components/Link';
import Media from '../components/Media';
import SEO from '../components/seo';
import Section from '../components/Section';
import image1 from '../images/investors-in-people.png';
import image2 from '../images/civea.svg';
import image3 from '../images/bpa.svg';
import image4 from '../images/ssip-logo.png';
import image5 from '../images/isoqar.svg';
import image6 from '../images/csa.svg';
import image7 from '../images/armed-forces-covenant.svg';
import image8 from '../images/ECB.png';
import { useMemo } from 'react';
import styled from 'styled-components';

const CostOfLivingBanner = styled.div`
  border: 1px solid #dadeeb;
  background: #f1f4f8;
  padding: 8px 24px;
  border-radius: 4px;
`;

const HomePage = () => {
  return (
    <>
      <SEO
        title='Certificated Enforcement and Revenue Collection'
        description={`
                    Jacobs are the leading Debt Recovery and Enforcement Services provider in the
                    United Kingdom, with 60 years experience working with local authorities across
                    the country.
                `}
      />
      <HeroSection paddingTop='8px'>
        <Heading1 marginTop='120px' maxWidth='450px'>
          A new standard in enforcement
        </Heading1>
        <Paragraph margin='32px 0 64px 0 !important' maxWidth='580px'>
          Jacobs lead the profession in providing seamless Enforcement and Debt
          Collection services by working in partnership with our Local
          Authority, Commercial clients and His Majesty’s Courts and Tribunals
          Service.
        </Paragraph>

        <Button
          as='a'
          href='/pay-now'
          width={240}
          height={48}
          icon={faCreditCard}
          marginRight='20px'
          marginTop='16px'
        >
          Pay Now
        </Button>

        <Button
          backgroundColor='primary'
          as='a'
          href='/services'
          width={240}
          height={48}
          icon={faThLarge}
          marginTop='16px'
        >
          Self-Service
        </Button>
      </HeroSection>
      <Section backgroundColor='white' paddingTop='40px'>
        <CostOfLivingBanner>
          <strong>
            Are you are having problems because of the cost-of-living crisis?
          </strong>
          <br /> We want to ensure you are aware of all the assistance available
          to you, please visit the website{' '}
          <Link href='https://helpforhouseholds.campaign.gov.uk/'>
            Help for Households
          </Link>{' '}
          to see details of what may be available to you or{' '}
          <Link href='/cost-of-living'>click here</Link> for more detailed links
          and information.
        </CostOfLivingBanner>
      </Section>

      <Section backgroundColor='white' paddingTop='80px' paddingBottom='100px'>
        <Grid
          gridTemplateRows={useMemo(
            () => ({
              xs: 'auto auto',
              lg: 'auto',
            }),
            []
          )}
          gridTemplateColumns={useMemo(
            () => ({
              xs: '1fr',
              lg: '0.8fr 1fr',
            }),
            []
          )}
          gridColumnGap='60px'
        >
          <div>
            <Heading2 fontWeight='900' fontSize='1.2rem'>
              About Jacobs
            </Heading2>

            <Paragraph
              color='text-darkblue'
              fontWeight='500'
              lineHeight='1.4em'
              fontSize='1.2em'
            >
              Simon Jacobs and Paula Jacobs have led the organisation for over
              30 years, supported by an experienced, dynamic and specialist
              management team.
            </Paragraph>
          </div>

          <div>
            <Paragraph
              marginTop={useMemo(() => ({ lg: '45px' }), [])}
              fontWeight='400'
            >
              A family run firm with the right qualities and ethos at the centre
              of all that we do. Despite considerable growth, we still hold our
              core values to work in true partnership and as an extension to our
              clients’ services. With over 61 years in business, we currently
              work with over 175 Local Authorities across England and Wales and
              are the primary contractor in Wales for HMCTS to manage all
              Warrants of Control and Warrants of Arrest in relation to the
              enforcement of Criminal Financial Impositions.
              <br />
            </Paragraph>
            <Link
              href='/about'
              style={{ textDecoration: 'none', fontSize: '18px' }}
            >
              Find out more
            </Link>
          </div>
        </Grid>
      </Section>

      <Section backgroundColor='grey' paddingTop='74px' paddingBottom='80px'>
        <Grid
          alignItems='center'
          justifyItems='center'
          gridTemplateRows={useMemo(
            () => ({
              xs: 'auto auto auto auto auto auto auto auto',
              md: 'auto auto auto auto',
              lg: 'auto auto auto',
            }),
            []
          )}
          gridTemplateColumns={useMemo(
            () => ({
              xs: '1fr',
              md: '1fr 1fr',
              lg: '1fr 1fr 1fr 1fr 1fr',
            }),
            []
          )}
          gridGap={useMemo(
            () => ({
              xs: '40px',
              md: '80px',
            }),
            []
          )}
          css={`
            @media all and (-ms-high-contrast: none),
              (-ms-high-contrast: active) {
              justify-content: space-between;
              * {
                min-width: 25%;
              }
            }
          `}
        >
          <Media src={image1} alt='' />

          <Media src={image2} alt='' />

          <Media src={image8} alt='' />

          <Media src={image3} alt='' />

          <Media src={image4} alt='' />

          <Media src={image5} alt='' />

          <Media src={image6} alt='' />

          <Media src={image7} alt='' />

          <div>
            <iframe
              src='https://registry.blockmarktech.com/certificates/d137bcc0-2fa4-4f39-8482-353f8508c710/widget/?tooltip_position=corner&theme=transparent&hover=t'
              style={{ border: 'none', height: '110px', width: '110px' }}
            ></iframe>
          </div>
        </Grid>
      </Section>
    </>
  );
};

export default HomePage;
